import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import apiClient from "../api.config";
import useDocumentTitle from "../components/useDocumentTitle";
import LogoFoundation from "../images/WHUF_Horizontal_Digital.png";

export default function UserResetPassword() {
  useDocumentTitle("User Reset Password");

  const Urlparams = useLocation().search;
  const [password, setPassword] = useState("");
  const [Confirmpassword, setConfirmpassword] = useState("");
  const Params_userID = new URLSearchParams(Urlparams).get("userID");
  const Params_resetToken = new URLSearchParams(Urlparams).get("resetToken");
  const navigate = useNavigate();

  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  // console.log(Params_userID,Params_resetToken);
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function ValidateUser() {
    if (Params_userID === null || Params_resetToken === null) {
      toast.error("Invalid Reset token");
    } else if (Confirmpassword.trim().length === 0 && password.trim().length === 0) {
      toast.error("Please Enter Password and Confirm Password");
    } else if (password.trim().length === 0) {
      toast.error("Please Enter Password");
    } else if (Confirmpassword.trim().length === 0) {
      toast.error("Please Enter Confirm Password");
    } else if (password != Confirmpassword) {
      toast.error("Invalid Confirm Password");
    } else {
      try {
        const response = await toast.promise(
          apiClient.post(
            APIURL + "/auth/reset",
            {
              userID: Params_userID,
              userPasswordToken: Params_resetToken,
              userPassword: Confirmpassword,
            },
            {
              withCredentials: true,
              credentials: "same-origin",
            }
          ),
          {
            pending: "Please wait...",
          }
        );

        if (response.data.content === "Token Matches. Password Reset Succesfully") {
          toast("Passwort Reset Successfully", { type: "success" });
          await timeout(2000);
          navigate("/login");
        } else {
          toast("Passwort Reset Fail", { type: "error" });
        }
      } catch (error) {
        console.log(error);
        toast.error(`${error.message} Node Not found`);
      }
    }
  }

  return (
    <>
      <section className="reset-password-section font-face-sh">
        <div className="container">
          <div className="login-box mx-auto mt-4" style={{ maxWidth: 360 }}>
            <div className="login-header text-center pt-1">
              <Link to={"/"}>
                <img className="logo mb-3" src={LogoFoundation} style={{ maxHeight: 70 }} />
              </Link>
              <h3 className="title text-center fs-22 mb-3 pb-1" id="title" style={{ display: "block" }}>
                Reset your password
              </h3>
            </div>
            <div id="emailPassword" className="d-block">
              <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="New Password" className="input" />

              <input type="password" onChange={(event) => setConfirmpassword(event.target.value)} placeholder="Confirm New Password" className="input" />

              <button
                onClick={ValidateUser}
                className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-pill py-3 mt-3 mb-2 lh-1 desktop-btn text-light d-block"
                style={{ backgroundColor: "rgb(5, 179, 4)" }}
                type="button"
                name="button"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
