import React from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import useDocumentTitle from "../components/useDocumentTitle";

export default function PrivacyPolicy() {
  useDocumentTitle("Privacy Policy");
  return (
    <>
      <Header />
      <section className="content-section">
        <div className="container">
          <div className="terms-conditions-text">
            The following documents outline our Privacy Policy
            <br />
            <br />
            <h2>OUR APPROACH</h2>
            This Privacy Policy (the “Policy” ) sets out how we, PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU, process the personal data of
            visitors to our website, www.westhamdraw.com along with participants in our lotteries and promotions (“User”). If you have any questions about this
            Policy, please contact us at:<a href="mailto:hello@playfundwin.com"> hello@playfundwin.com </a>
            <br />
            <br />
            <h2>PERSONAL DATA</h2>
            <br />
            Personal data means any information relating to a person who can be identified either directly or indirectly; it may include name, gender, date of birth, email address,
            username, photo, IP address, information about preferences, patterns of behaviour, and location data (“Personal Data”).
            <br />
            <br />
            <h2>DATA CONTROLLERS</h2>
            <br />
            The successful operation of West Ham United Foundation requires that PFW Holdings Ltd as the data processor and West Ham United Foundation act as data controllers. PFW Holdings Ltd
            becomes the data controller only when required to carry out regulatory record keeping. West Ham United Foundation Privacy Policy is available here:{" "}
            <a href="/privacy-policy/">Privacy Policy</a>
            <br />
            <br />
            <h2>HOW WE USE DATA &amp; CONSENT</h2>
            <br />
            We will only process your Personal Data, in accordance with applicable law, for the following purposes:
            <br />
            (a) creating and maintaining your user account;
            <br />
            (b) offering goods, services and suggestions to you in a personalised way. With your permission, for example, you may receive communications by emails, post, phone or
            mobile messaging related to charitable updates or promotions. You can unsubscribe from these promotional communications at any time;
            <br />
            (c) enabling our suppliers and service providers to carry out certain functions on our behalf, including identity, verification, technical, logistical or other
            functions, as may be required, in order to fulfil your orders;
            <br />
            (d) listing or combining your Personal Data with other information we get from third parties to help understand your needs and provide you with a better service;
            <br />
            (e) ensuring the security of your account and our business, preventing or detecting fraud or abuses of our website;
            <br />
            (f) developing and improving our services, for example, by reviewing use of our website and services;
            <br />
            (g) to comply with applicable law, for example, in response to a request from a court or regulatory body, where such request is made in accordance with the law; The
            legal basis for us processing your Personal Data for the purposes described above will typically be because you have provided us with your consent. However, we may also
            rely on other legal grounds, for example, where the processing is necessary:
            <br />
            (a) to fulfil a contract that we have in place with you;
            <br />
            (b) for our legitimate business interests; or
            <br />
            (c) for compliance with a legal obligation to which we are subject.
            <br /> <br />
            <br />
            <h2>DISCLOSURE</h2>
            <br />
            There are circumstances where we wish to disclose or are compelled to disclose your Personal Data to third parties. This will only take place in accordance with
            applicable law and for the purposes listed above. These scenarios include disclosure:
            <br />
            (a) to our subsidiaries, branches or associated offices;
            <br />
            (b) to our outsourced service providers or suppliers to facilitate the provision of our services to our Users, for example, the disclosure to our data centre provider
            for the safe keeping of your Personal Data; or financial institutions to complete a prize payout;
            <br />
            (c) to third party service providers and consultants in order to protect the security or integrity of our business, including our databases and systems and for business
            continuity reasons;
            <br />
            (d) to another legal entity, on a temporary or permanent basis, for the purposes of a joint venture, collaboration, financing, sale, merger, reorganisation, change of
            legal form, dissolution or similar event. In the case of a merger or sale, your Personal Data will be permanently transferred to a successor company;
            <br />
            (e) to public authorities where we are required by law to do so; and
            <br />
            (f) to any other third party where you have provided your consent.
            <br />
            <br />
            <h2>INTERNATIONAL TRANSFER</h2>
            <br />
            We may transfer your Personal Data to a third party in countries outside the country from which it was originally collected for further processing in accordance with
            the purposes set out in paragraph 3 above. In particular, your Personal Data may be transferred and to our outsourced service providers located abroad. In these
            circumstances we will, as required by applicable law, ensure that your privacy rights are adequately protected by appropriate technical, organisation, contractual or
            other lawful means. Please contact us for a copy of the safeguards which we have put in place to protect your Personal Data and privacy rights in these circumstances.
            <br />
            <br />
            <h2>RETENTION</h2>
            <br />
            Your Personal Data will be retained for so long as you are registered with us or your details are required to provide our services, unless longer retention is required
            to comply with regulatory mandates or where we have a legitimate and lawful reason. We may keep an anonymised form of your Personal Data, which will no longer refer to
            you, for statistical purposes without time limits, to the extent permitted by applicable law. All Personal Data is stored in highly secure data centres that comply with
            the latest ISO 27001 security management standards. Your payment details are never stored on our servers.
            <br />
            <br />
            <h2>DATA SUBJECT RIGHTS</h2>
            <br />
            Data protection law provides individuals in certain countries with numerous rights, including the right to: access, rectify, erase, restrict, transport, and object to
            the processing of, their Personal Data. Individuals may also have the right to lodge a complaint with the relevant data protection authority if they believe that their
            Personal Data is not being processed in accordance with applicable data protection law. If you would like to exercise any of your rights, please contact us at:{" "}
            <a href="mailto:hello@playfundwin.com"> hello@playfundwin.com </a>
            <br />
            <br />
            <h2>ANALYTICS</h2>
            <br />
            In order to collect the information as described above, we may use analytics technology on our website, including Google Analytics, among others. This technology
            collects information, usually in an anonymous form about how Users use our website. This is accomplished by placing a small text file or ‘cookie’ on a User’s computer.
            This allows the website to remember convenient information that will allow for a better website experience.
            <br />
            Users can also opt out of Google’s advertising tracking cookie here, or use a browser plugin to opt out of all Google Analytics tracking software found here.
            <br />
            <br />
            <h2>LINKED WEBSITES</h2>
            <br />
            Please note that any websites that may be linked to our websites are subject to their own privacy policy.
            <br />
            <br />
            <h2>POLICY CHANGES</h2>
            <br />
            Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to
            see any updates or changes to our privacy policy.
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
