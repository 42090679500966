import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { React, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "../images/WHUFK_Logo_White.png";
// import HeaderRightLogo from '../images/nottingham-forest.png'
import SignUp from "../images/playicon.png";
import Trophy from "../images/winicon.png";
import Charity from "../images/fundicon.png";
import DesktopImage2 from "../images/Desktop2.png";
import LogoFoundationGray from "../images/WHUFK_Logo_White.png";
import LogoFoundation from "../images/WHUF_Horizontal_Digital.png";
import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import Facebook from "../images/facebook.svg";
import Linkedin from "../images/linkedin.svg";
import Twitter from "../images/twitter.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import LogoRed from "../images/WHUFK_Logo_White.png";
import "../images/HeroBg.png";
import "../App.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import useDocumentTitle from "../components/useDocumentTitle";
import Moment from "moment";
import PrizeSlider from "../components/prizeSlider";

import { toast, ToastContainer } from "react-toastify";
import NextArrow from "../images/next-arrow.svg";
import Cross from "../images/cross.svg";
import FreeEntryIcon from "../images/free-entry-icon.svg";
import { Globalvar } from "../components/global";
import StripeForm from "../components/stripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { Col } from "react-bootstrap";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const GALLERY_PHONE = [
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/92c22bff-7c03-47da-9240-04e4bc64aaa9-Chadwell%20Heath%20AOI.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/35b22f9f-8c5a-40a5-9978-f4e65aea5fdf-Najma%20PL%20Kicks%20Local%20Legend.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/49ada73f-071c-4df8-8f86-d3e1a44679ec-PLCF%20Ilford%20Inspired%20Youth%2030SEP21-8128.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/edfa9351-cc15-414d-b3ce-7145fe2e253e-WestHam_Graphic_2.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/6ac3aa60-fe47-4beb-ab80-affbeea22990-WestHam_Graphic_3.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/968cdb1e-4c3b-4378-973e-e30b685113af-WestHam_Graphic_4.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/cee9a42f-5d0e-4f22-9a62-925a93826c6e-WestHam_Graphic_5.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/acab3a42-1b91-48e6-8ddd-b33ac886c940-WestHam_Graphic_6.jpg",
  "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/d162ff0d-ae57-4b6a-8d37-10c342849552-WestHam_Graphic_7.jpg",
];
// const LogoRed = "https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/8e76022b-9ddc-4f1b-a9fb-10d1f03ae438-WHUF_Horizontal_Digital.png";

export default function Home() {
  const navigate = useNavigate();
  useDocumentTitle("Home");
  const [scroll, setScroll] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  const [login, setLogin] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").loggedin : false);
  const [DrawData, setDrawData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(true);
  const [Freemodal, setFreemodal] = useState(false);
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [amount, setAmount] = useState(5);
  const [entries, setEntries] = useState(1);
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [userEmail, setEmail] = useState();
  const [fullName, setFullName] = useState("");
  const [oneShow, setOneShow] = useState(false);
  const [isoneoff, setIsoneoff] = useState(false);
  const [isStripe, setStripe] = useState(false);
  const [PrizeData, setPrizeData] = useState([]);
  const [UserID, setUserId] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").userID : "");
  const userData = secureLocalStorage.getItem("LogObject");

  const parser = new DOMParser();
  const [totalQuantities, setTotalQuantities] = useState(0);
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showPlayButton, setShowPlayButton] = useState(true);

  const handlePlay = () => {
    setShowPlayButton(false);
    document.getElementById("video").play();
  };

  const handlePause = () => {
    setShowPlayButton(true);
  };

  function logout() {
    secureLocalStorage.clear();
    axios.delete(APIURL + "/auth/logout");
    setLogin(false);
  }

  useEffect(() => {
    // window.addEventListener("scroll", () => {
    //   setScroll(window.scrollY > 10);
    // });
    axios.get(APIURL + "/sites/" + siteId + "/draws").then((res) => {
      console.log(`[DATA]: \t`, res.data.content);
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter((item) => item.drawIsClosed !== 1 && item.drawIsVisible === 1 && item.drawIsActive === 1);
      const urlParams = new URLSearchParams(window.location.search);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      // Check if Entry is for the event Prize Draw
      if (urlParams.has("eventPrizeDraw")) {
        const eveningPrizeDrawId = "705bd487-133c-42bd-8e58-34702073c6d6";
        const eveningPrizeDraw = alldraw.find((item) => item.drawID === eveningPrizeDrawId);
        if (eveningPrizeDraw) {
          selectedDraw = eveningPrizeDraw;
        }
      }
      setDrawData(selectedDraw);
    });
  }, []);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function hideModal() {
    setOneShow(false);
  }

  Globalvar.amount = 5;
  Globalvar.entries = 1;

  function calculateTotalQuantity(PrizeData) {
    let sum = 0;
    {
      PrizeData.forEach((item) => {
        sum += item.prizeQuantity;
      });
    }
    return sum;
  }

  useEffect(() => {
    let selectedDrawID_ = "";
    axios.get(APIURL + "/sites/" + siteId + "/draws").then((res) => {
      console.log(`[DATA1]: \t`, res.data.content);
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter((item) => item.drawIsClosed !== 1 && item.drawIsVisible === 1 && item.drawIsActive === 1);

      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      selectedDrawID_ = selectedDraw?.drawID;
      setDrawData(selectedDraw);
    });

    if (selectedDrawID_.length > 1) {
      axios
        .get(APIURL + `/draws/${selectedDrawID_}/prizes`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          setPrizeData(res?.data?.content);
          setTotalQuantities(calculateTotalQuantity(res?.data?.content));
        });
    }
  }, []);

  const handleClick = (param, value, tickets) => {
    switch (param) {
      case "first":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(true);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "second":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "third":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fourth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(true);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fifth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(true);
        setIsActive6(false);
        return;
      case "sixth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(true);
        return;
      default:
        setEntries(5);
        setAmount(25);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
    }
  };

  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);
  }

  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);

    // Data Layer Push for Add to Cart event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData?.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries,
          },
        ],
      },
    });

    // Data Layer Push for Begin Checkout event
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData?.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries,
          },
        ],
      },
    });
  }

  async function checkConsents() {
    const fn = fullName.split(" ");

    // const checkConsents = () => {
    if (!consent3) {
      toast("Please read and accept Terms & Conditions", { type: "error" });
    } else if (fullName === "") {
      toast.error(`Please Enter Your valid Full Name`);
    } else if (!fn[0] || fn[0] === "") {
      toast.error(`Please Enter Your First and last Name`);
    } else if (!fn[1] || fn[1] === "") {
      toast.error(`Please Enter Your last Name`);
    } else if (userEmail == "") {
      toast("Please Enter the Email Address", { type: "error" });
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail)) {
      toast.error(`Invalid Email`);
    } else {
      setStripe(true);
      setOneShow(true);

      //ValidateEmail
      // const POSTCODER_KEY =
      //   process.env.POSTCODER_API_KEY || "PCWW5-FVLAY-K6FGB-KSTYM";
      // await axios
      //   .get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/email/${userEmail}`)
      //   .then((res) => {
      //     console.log("res.data", res.data);
      //     if (res.data.valid) {
      //       setStripe(true);
      //       setOneShow(true);
      //     } else {
      //       toast.error(`Invalid Email`);
      //     }
      //   });
    }
    // }
  }

  let drawContent;
  let nextFixtureName = "";
  let leagueName = "";
  let fixtureData = "";

  console.log(`[DrawData]: \t`, DrawData);
  try {
    if (DrawData) {
      drawContent = JSON.parse(DrawData.drawContent);
    }
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  if (drawContent) {
    // Access properties from the parsed JSON
    nextFixtureName = drawContent.nextfxitureName;
    leagueName = drawContent.leagueName;
    fixtureData = drawContent.fixtureData;

    // Use nextFixtureName in your JSX or template
  } else {
    // Handle the case where parsing failed
    // This could be due to invalid JSON format
  }

  return (
    <>
      <header
        className={
          scroll
            ? "home transation-ease-in-out site-header font-face-sh py-2 position-sticky top-0 bg-branding-1 z-index-9"
            : "transation-ease-in-out site-header bg-branding-1 bg-font-face-sh py-2"
        }
      >
        <div className="container">
          <div className="inner-header row d-flex align-items-center">
            <div className="logo col-sm-6 col-md-6 col-lg-6 col-6">
              <Link to={"/"}>
                <img className="start-0 not-responsive" src={scroll ? LogoWhite : LogoRed} alt="logo-white" style={{ width: 270 }} />
              </Link>
              <Link to={"/"}>
                <img className="start-0 only-responsive d-none" src={LogoWhite} alt="logo-white" style={{ width: 270 }} />
              </Link>
            </div>
            <div className="desktop-menu col-md-6 d-flex justify-content-end align-items-center">
              {login ? (
                <>
                  <DropdownButton align="end" title="Account" id="dropdown-menu-align-end" className="text-white transparent">
                    <Dropdown.Item eventKey="1" href="/account">
                      My Impact
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/account/prizes">
                      Prizes
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/account/subscription">
                      Manage Subscription
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="6" href="/account/payment-method">
                      Manage Payment
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4" href="/account/details">
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="5" onClick={logout}>
                      Log out
                    </Dropdown.Item>
                  </DropdownButton>
                  <Link
                    to={"/subscription-plan"}
                    className="py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh"
                    style={{ color: "white" }}
                  >
                    Enter The Draw
                  </Link>
                </>
              ) : (
                <>
                  <div className="login-signup py-3 px-4 bg-white rounded-pill branding-2 border border-2 border-white ">
                    {/* <img src={require('../images/user.png')} alt="User"/> */}
                    <Link to={"/login"} className="branding-1 text-decoration-none fs-6 font-face-sh-bold" style={{ marginRight: 20 }}>
                      Login
                    </Link>
                    /
                    <Link to={"/signup"} className="branding-1 text-decoration-none fs-6 ms-3 lh-1 font-face-sh-bold">
                      Sign up
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none">
              <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
                <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
                <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
                <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mySidebar"
          className={
            Sidebar
              ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4"
              : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"
          }
        >
          <div className="logo-red col-md-12">
            <Link to={"/"}>
              <img className="start-0" src={LogoRed || ""} alt="logo-white" style={{ width: 270 }} />
            </Link>
          </div>
          <div className="mobile-menu-nav col-md-12 mt-5 d-flex flex-column">
            {login ? (
              <>
                <Link
                  to={"/subscription-plan"}
                  className="text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh"
                  style={{ color: "white" }}
                >
                  Enter The Draw
                </Link>
                <div className="fs-6 mb-1" style={{ color: "#999" }}>
                  <strong>Account</strong>
                </div>
                {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
          <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
                <Link to={"/account"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  My Impact
                </Link>
                <Link to={"/account/prizes"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Prizes
                </Link>
                <Link to={"/account/subscription"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Subscription
                </Link>
                <Link to={"/account/payment-method"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Payment
                </Link>
                <Link to={"/account/details"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Settings
                </Link>
                <hr />
                <Link onClick={logout} to={"/"} className="black-clr text-decoration-none fs-5 branding-1-hover">
                  Log Out
                </Link>
              </>
            ) : (
              <>
                <Link to={"/login"} className="black-clr text-decoration-none fs-5 font-face-sh branding-1-hover">
                  Login
                </Link>
                <Link
                  to={"/signup"}
                  className="py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1"
                >
                  Join Now
                </Link>
              </>
            )}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3 mt-auto ">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                <div className="d-flex">
                  <a className="branding-2 text-decoration-none" href="https://facebook.com/PlayFundWin/" target="_blank">
                    <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                  </a>
                  <a className="branding-2 text-decoration-none" href="https://twitter.com/PlayFundWin" target="_blank">
                    <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                  </a>
                  <a className="branding-2 text-decoration-none" href="https://www.instagram.com/playfundwin" target="_blank">
                    <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2" style={{ fontSize: 14 }}>
                  West Ham United Foundation Prize Draw is operated by{" "}
                  <a className="text-decoration-none branding-1 branding-1-hover" href="https://playfundwin.com/" target="_blank">
                    Play Fund Win
                  </a>
                </p>
                <div className="d-flex font-face-sh-bold" style={{ fontSize: 14 }}>
                  <Link className="branding-1 text-decoration-none pe-5 branding-1-hover" onClick={() => window.scrollTo(0, 0)} to={"/terms-conditions"}>
                    Terms & Conditions
                  </Link>
                  <Link className="branding-1 text-decoration-none branding-1-hover" onClick={() => window.scrollTo(0, 0)} to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="hero-section herosec-img font-face-sh py-sm-" style={{ marginTop: "-165px", padding: "170px 0 20px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-12 text-center " style={{ paddingTop: "190px" }}>
              <h1
                className="text-white title-managed position-relative lh-4 w-auto mt-4 mb-0 display-4 font-face-sh-bold text-uppercase"
                style={{
                  background: "#51B0E2",
                  paddingBottom: "1px",
                  paddingTop: "10px",
                }}
              >
                It’s a win win!
              </h1>
              <div className="w-100 bg-white text-center" style={{ paddingBottom: "1em" }}>
                <h3 className="w-100 fs-5 branding-2 bg-white lh-2 p-3 fw-normal mr-0">
                  From just £5 per ticket, you'll help the West Ham United Foundation on their mission to inspire better futures for all. <br />
                </h3>
                <span class="draw-timer"> Draw closes on {Moment(DrawData.drawEndDate).format("DD/MM/YYYY")}</span>
                {/* <div class="bg-white w-100 " style={{ display: "flex", justifyContent: "center" }}></div> */}
              </div>
            </div>

            <div className="prizes-section-data mb-3 text-center d-flex border-white border-bottom rounded-top" style={{ justifyContent: "center" }}>
              <div className="prizes-section-subdata">
                <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                  WIN BIG! Enter for your chance to win amazing Hammers experiences and merchandise.
                </h3>
              </div>
            </div>
            <PrizeSlider />
            <div className="join-now-button text-center">
              <Link to={"/signup"} className="btn text-uppercase bg-white border-0 w-auto rounded-pill py-3 mt-1 lh-1 desktop-btn branding-1 ">
                Join Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="ticket-data bg-branding-btn-sec">
        <div className="container">
          <div className="ticket-section pb-5">
            <div className="py-1 d-flex align-items-center justify-content-center mb-3">
              <img src={require("../images/playicon.png")} />
              <h2 className="mt-4 mx-3">
                <span className="position-relative display-5 branding-2 font-face-sh-bold" style={{ color: "white" }}>
                  ENTER THE DRAW
                </span>
              </h2>
            </div>
            {/* <p className='fs-5 text-center text-white'>How many tickets would you like to buy? £5 per ticket</p> */}

            {!paymentDetails && SubscriptionPlan ? (
              <section className="subscription-plan font-face-sh">
                <div className="subscription-plan-container mx-auto">
                  <div
                    className={Freemodal ? "steps-subscription-plan position-relative pt-0 d-none" : "steps-subscription-plan position-relative pt-0 d-block"}
                    style={{ zIndex: 999 }}
                  >
                    <div className="step-subscription-plan-selector pt-4">
                      <ul className="d-flex unstyled list-unstyled gap-3">
                        <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 1)}>
                          <div
                            className="ticket-title-sec px-3 py-5 position-relative"
                            style={{
                              borderRadius: 6,
                              filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))",
                            }}
                          >
                            <h4
                              className="fs-5 text-center mb-4 mb-0"
                              style={{
                                color: "#302d51",
                                borderBottom: "2px solid #001838",
                                paddingBottom: 15,
                              }}
                            >
                              1 Ticket
                            </h4>
                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">5</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">{/*per month*/}</span>}
                            </div>
                          </div>
                        </li>
                        <li className={isActive2 ? "active" : ""} onClick={() => handleClick("second", 10, 2)}>
                          <div
                            className="ticket-title-sec px-3 py-5 position-relative"
                            style={{
                              borderRadius: 6,
                              filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))",
                            }}
                          >
                            <h4
                              className="fs-5 text-center mb-4 mb-0"
                              style={{
                                color: "#302d51",
                                borderBottom: "2px solid #001838",
                                paddingBottom: 15,
                              }}
                            >
                              2 Tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">10</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">{/*per month*/}</span>}
                            </div>
                          </div>
                        </li>
                        <li className={isActive3 ? "position-relative active" : "position-relative"} onClick={() => handleClick("third", 25, 5)}>
                          <div
                            className="subscription-plan-emphasis branding-1 d-inline-block lh-normal position-absolute start-0 end-0 m-auto bg-white"
                            style={{
                              fontSize: 14,
                              padding: "7px 6px 7px 7px",
                              width: 150,
                              top: -40,
                              zIndex: 999,
                              textAlign: "center",
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20,
                              color: "#51B0E2",
                            }}
                          >
                            Popular
                          </div>
                          <div
                            className="ticket-title-sec px-3 py-5 position-relative"
                            style={{
                              borderRadius: 6,
                              filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))",
                            }}
                          >
                            <h4
                              className="fs-5 text-center mb-4 mb-0"
                              style={{
                                color: "#302d51",
                                borderBottom: "2px solid #001838",
                                paddingBottom: 15,
                              }}
                            >
                              5 Tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">25</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">{/*per month*/}</span>}
                            </div>
                          </div>
                        </li>
                        <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 50, 10)}>
                          <div
                            className="ticket-title-sec px-3 py-5 position-relative"
                            style={{
                              borderRadius: 6,
                              filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))",
                            }}
                          >
                            <h4
                              className="fs-5 text-center mb-4 mb-0"
                              style={{
                                color: "#302d51",
                                borderBottom: "2px solid #001838",
                                paddingBottom: 15,
                              }}
                            >
                              10 Tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">50</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">{/*per month*/}</span>}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/*<div*/}
                    {/*  className="subscription-plan-selector-amountarea bg-white p-3 mt-4"*/}
                    {/*  style={{ borderRadius: 6 }}*/}
                    {/*>*/}
                    {/*  <div className="row align-items-center">*/}
                    {/*    <div className="col-lg-6 col-md-6 col-sm-12">*/}
                    {/*      <div className="subscription-plan-price d-flex justify-content-center">*/}
                    {/*        <div className="subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1">*/}
                    {/*          £*/}
                    {/*        </div>*/}
                    {/*        <div className="subscription-plan-price_amount branding-1 display-3 font-face-sh-bold">*/}
                    {/*          {amount}*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*          className="subscription-plan-price_term text-black lh-1 mt-3 ms-1"*/}
                    {/*          style={{ fontSize: 12 }}*/}
                    {/*        >*/}
                    {/*          {isoneoff ? (*/}
                    {/*            ''*/}
                    {/*          ) : (*/}
                    {/*            <>*/}
                    {/*              per*/}
                    {/*              <br />*/}
                    {/*              month*/}
                    {/*            </>*/}
                    {/*          )}*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-6 col-md-6 col-sm-12">*/}
                    {/*      <div className="subscription-plan-selector-entries d-flex align-items-center">*/}
                    {/*        <div*/}
                    {/*          className="draw-entries-icon position-relative"*/}
                    {/*          style={{*/}
                    {/*            backgroundColor: '#f3f2fb',*/}
                    {/*            width: 52,*/}
                    {/*            height: 52,*/}
                    {/*            borderRadius: '50%',*/}
                    {/*          }}*/}
                    {/*        ></div>*/}
                    {/*        <ul*/}
                    {/*          className="unstyled list-unstyled ul-tick ms-3 mb-0"*/}
                    {/*          style={{ fontSize: 12 }}*/}
                    {/*        >*/}
                    {/*          <li>Auto-entry into every draw</li>*/}
                    {/*          <li>Regular charitable support</li>*/}
                    {/*        </ul>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="step-subscription-button text-center mb-4 d-flex justify-content-center">
                      {Moment() < Moment(DrawData?.drawStartDate) ? (
                        <>
                          <button
                            onClick={handleShow}
                            className="btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                            style={{ width: 400, height: 48 }}
                          >
                            <span>{isoneoff ? "Enter Draw" : "Enter Now"}</span>
                          </button>
                          <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-startShow">
                            <Modal.Header closeButton>
                              <Modal.Title>Draw has not started yet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="text-center">
                                <img className="mx-auto w-50 mb-3" src={require("../images/WHUFK_Logo_White.png")} style={{ width: 66 }} alt={""} />
                                <p className="fs-3">
                                  This draw will start at{" "}
                                  <span className="branding-2">{DrawData?.drawEndDate ? Moment(DrawData?.drawStartDate).format("LLL") : "-----, /--/--/-- 00:00PM"}</span>
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <span variant="secondary" onClick={handleClose}>
                                Close
                              </span>
                            </Modal.Footer>
                          </Modal>
                        </>
                      ) : (
                        <button
                          onClick={() => toPaymentDetails(true)}
                          className="btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: 400, height: 48 }}
                        >
                          <span>{isoneoff ? "Enter Draw" : "Enter Now"}</span>
                          <img className="position-absolute top-0 bottom-0 my-auto" src={NextArrow} style={{ height: 40, right: 4 }} />
                        </button>
                      )}
                    </div>
                    <div className="form-check form-switch d-flex justify-content-center pt-0">
                      {/*<input*/}
                      {/*  checked={isoneoff}*/}
                      {/*  onChange={() => setIsoneoff(!isoneoff)}*/}
                      {/*  className="form-check-input me-2"*/}
                      {/*  type="checkbox"*/}
                      {/*  id="flexSwitchCheckDefault"*/}
                      {/*/>*/}
                      {/*<label*/}
                      {/*  className="form-check-label text-white"*/}
                      {/*  htmlFor="flexSwitchCheckDefault"*/}
                      {/*>*/}
                      {/*  {!isoneoff*/}
                      {/*    ? 'Tick to make a one-off entry into the next draw'*/}
                      {/*    : 'Untick to make a Subscription entry into the next draw'}*/}
                      {/*</label>*/}
                    </div>
                  </div>
                  <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                    <div className="free-entry-modal_title_block mb-5 pb-5">
                      <img
                        className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                        src={Cross}
                        style={{
                          width: 56,
                          boxShadow: "0 4px 16px rgb(20 19 38 / 30%)",
                          cursor: "pointer",
                        }}
                        onClick={() => setFreemodal(!Freemodal)}
                      />
                      <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                      <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                        While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                      </p>
                    </div>
                    <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                      <div className="free-entry-icon">
                        <img src={FreeEntryIcon} style={{ width: 24 }} />
                      </div>
                      <div className="free-entry-description ps-4 fs-14 lh-1">
                        <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                        <p>
                          * First Name
                          <br />
                          * Last Name
                          <br />* Email
                        </p>
                        <p>
                          to: PFW Holdings Ltd
                          <br />
                          Play Fund Win Office #300,
                          <br />4 Blenheim Court,
                          <br />
                          Peppercorn Close,
                          <br />
                          Peterborough,
                          <br />
                          PE1 2DU
                          <br />
                          <br />
                          You will be entered into the prize draws for the month in which we receive your post entries.
                          <br />
                          <br />
                          Note: This will not result in any contributions to the Foundation
                        </p>
                        <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                          <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : paymentDetails ? (
              <section className="personal-details font-face-sh py-5">
                <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                      <div
                        className="step-plan_step branding-1 rounded-pill text-center mx-auto"
                        style={{
                          backgroundColor: "#f7f7fd",
                          width: 112,
                          height: 45,
                          lineHeight: "45px",
                        }}
                      >
                        <strong>Step 2 of 2</strong>
                      </div>
                      {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                      <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                        {isoneoff ? "" : "We’ll take your amount "}
                      </p>
                      <form method="post">
                        {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                        {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                        {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                        <div className="form-checkbox">
                          <ul className="checkbox-list list-unstyled">
                            <li className="checkbox-item mb-4">
                              <label className="input-checkbox d-flex">
                                <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, I’d like to receive updates about West Ham United Foundation via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label className="input-checkbox d-flex">
                                <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, West Ham United Foundation Foundation can contact me about their work via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label
                                className="step-payment-details_consent_description mt-5 input-checkbox d-flex"
                                style={{
                                  backgroundColor: "rgb(198,29,35,.1)",
                                  color: "#302d51",
                                  padding: "14px 18px",
                                  borderRadius: 20,
                                  fontSize: 13,
                                }}
                              >
                                <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span>
                                  By clicking on Confirm you agree to West Ham United Foundation Draw{" "}
                                  <Link to={"/terms-conditions"} onClick={() => window.scrollTo(0, 0)} style={{ color: "#302d51" }}>
                                    Terms & Conditions of Use
                                  </Link>
                                  ,{" "}
                                  <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                    Privacy Policy
                                  </Link>{" "}
                                  and that you <strong>are at least 18 years old.</strong>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </form>
                      <button
                        onClick={checkConsents}
                        className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                        style={{ width: "100%", height: 48 }}
                      >
                        Pay now
                      </button>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                      <div
                        className="user-details-info bg-branding-1 rounded-4"
                        style={{
                          padding: "35px 35px 19px",
                          boxShadow: "0 15px 50px -30px #000000",
                        }}
                      >
                        <h3 className="text-light fs-5 mb-4">Summary</h3>
                        <div className="summary-info-impact pb-3">
                          <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                            {entries} chances to win
                          </div>
                          <div
                            className="summary-info-impact_period lh-1"
                            style={{
                              color: "rgb(255,255,255,0.7)",
                              paddingLeft: 35,
                            }}
                          >
                            every draw
                          </div>
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <div className="summary-info-price d-flex align-items-center pb-2">
                          <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                          <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                            {isoneoff ? "" : ""}
                          </span>
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                          Upgrade or cancel anytime
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>

      <section
        className="font-face-sh py-5"
        style={{
          marginTop: "3rem",
        }}
      >
        <div className="pb-4" style={{ display: "flex", justifyContent: "center" }}>
          <img src={LogoFoundation} alt="logo" style={{ maxWidth: "100%", height: "auto", width: "320px" }} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              {/* <h2 className="display-4  text-center mx-auto title-banner">
                West Ham United Foundation
              </h2> */}
              <h3 className="text-center">Inspiring Better Futures For All</h3>
              <div className="description-data" style={{ position: "relative" }}>
                <p className="fs-5 text-dark">
                  The West Ham United Foundation focuses on responding to local need, uniting the community, and providing an environment where all can thrive. With over 35
                  initiatives delivered across East London, Essex, and international sites, the Foundation engages with up to 50,000 people of all ages and abilities every year.
                </p>
                <p className="fs-5 text-dark">
                  Through their multi-layered and diverse approach, the Foundation has lots to offer. Whether it’s across health initiatives, helping those most vulnerable;
                  providing mentoring, education, and employment opportunities; keeping children and young people engaged and supported through sports and classroom provision, or
                  giving people the opportunity to follow their football dreams on the pitch while also gaining qualifications off the pitch.
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a
                    target="_blank"
                    href="https://www.whufc.com/club/community/foundation/about-us"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <button
                      className="btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                      style={{ width: "16em", height: 48 }}
                    >
                      <span>Find out more</span>
                    </button>
                  </a>
                </div>
                <br />
                <h5>
                  <b>How to Participate:</b>
                </h5>
                <p className="fs-5 text-dark">
                  This is the Foundation’s one-off prize draw in celebration of their Awareness Fixture against Liverpool FC on Saturday 27th April. This gives you the opportunity
                  to support the local community whilst having the chance to <b>WIN BIG.</b> Simply select your ticket quantity, and be in with a chance of winning!
                </p>

                {/*{login ? (*/}
                {/*  <Link*/}
                {/*    to="https://www.mancity.com/city-in-the-community"*/}
                {/*    target="_blank"*/}
                {/*    className="btn bg-branding-2 border-0 w-auto rounded-pill py-3  px-4 mt-1 lh-1 "*/}
                {/*    style={{color: "#fff"}}*/}
                {/*  >*/}
                {/*    Find out more*/}
                {/*  </Link>*/}
                {/*) : (*/}
                {/*  <Link*/}
                {/*    to="https://www.mancity.com/city-in-the-community"*/}
                {/*    target="_blank"*/}
                {/*    className="btn bg-branding-2 border-0 w-auto rounded-pill py-3  px-4 mt-1 lh-1"*/}
                {/*    style={{color: "#fff"}}*/}
                {/*  >*/}
                {/*    Find out more*/}
                {/*  </Link>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banners-bees">
        <div className="row ">
          <div className="col-lg-6 p-0 m-0">
            <img
              className="mx-auto"
              src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/06346c70-c6a2-4b4e-a3bb-0882b2303a1b-Rashid%20PL%20Trophy.jpg"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "Cover",
                objectPosition: "top",
              }}
            />
          </div>
          <div className="col-lg-6 p-0 m-0">
            <img
              className="mx-auto"
              src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/8f2660b6-5eba-4ea3-b6fd-e38cac7a8b50-WestHam_Graphic_1.jpg"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "Cover",
                objectPosition: "top",
              }}
            />
          </div>
        </div>
      </section>

      <section className="how-it-works_section font-face-sh bg-position py-5 bg-branding-2" style={{ marginTop: -10 }}>
        <div className="container p-5">
          <div className="how-it-works_title text-center">
            <h2 className="display-5 text-center mb-5 mt-2 pb-2 d-inline-block font-face-sh-bold text-white">How It Works</h2>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={SignUp} alt="signup" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative">Play</span>
                </h2>
              </div>

              <p className="black-clr fs-5 text-center lh-1 mt-1">Enter for your chance to win amazing prizes in the West Ham United Foundation Prize Draw.</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={Charity} alt="Charity" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative">Fund</span>
                </h2>
              </div>

              <p className="black-clr fs-5 text-center lh-1 mt-1">Help to support the fantastic work that West Ham United Foundation does.</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={Trophy} alt="Trophy" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative">Win</span>
                </h2>
              </div>
              <p className="black-clr fs-5 text-center lh-1 mt-1">The winners will be announced on the {Moment(DrawData?.drawEndDate).format("Do [of] MMMM YYYY [at] ha")}</p>
            </div>
          </div>
          <div className="how-it-works-button text-center pt-4">
            {login ? (
              <Link to={"/subscription-plan"} className="btn bg-branding-1 border-0 w-auto rounded-pill py-3  px-4 mt-1 lh-1 " style={{ color: "#fff" }}>
                Find out more
              </Link>
            ) : (
              <Link to={"/signup"} className="btn bg-branding-1 border-0 w-auto rounded-pill py-3  px-4 mt-1 lh-1" style={{ color: "#fff" }}>
                Find out more
              </Link>
            )}
          </div>
        </div>
      </section>
      <div className="section">
        <h3 className={"mb-0 py-4 display-5 text-center  mt-2 pb-2 font-face-sh-bold"}>Our Community</h3>
      </div>
      <section>
        <img
          src={DesktopImage2}
          style={{
            width: "100%",
          }}
          alt="banner-desktop-2"
          className="hide-on-mobile"
        />
      </section>

      <section className="gallery-mobile">
        {GALLERY_PHONE.map((item, index) => (
          <Col key={index}>
            <img src={item} alt="Gallery image" style={{ objectFit: "cover", width: "100%" }} />
          </Col>
        ))}
      </section>

      {/*<section className="banners-city">*/}
      {/*  <div className="container p-5">*/}
      {/*    <div className="col-lg-12 p-0 m-0">*/}

      {/*      <div className="position-relative rounded-4" style={{position: "relative", maxWidth: "100%"}}>*/}
      {/*        <video id="video" controls width="100%"*/}
      {/*               poster="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/video-poster.jpg"*/}
      {/*               onPause={handlePause}>*/}
      {/*          <source*/}
      {/*            src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/video/Final+-+Clean+with+New+Logo!(1).mp4"*/}
      {/*            type="video/mp4"/>*/}
      {/*          Sorry, your browser doesn't support embedded videos.*/}
      {/*        </video>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*<section className="social-share" style={{height: 600}}>*/}
      {/*  <div className="background-image p-5">*/}
      {/*    <div className="align-items-center row pt-5">*/}
      {/*      <h2 className="display-4 font-face-sh-bold text-center text-white">Want to know more about WestHam?</h2>*/}
      {/*      <div className="social-links">*/}
      {/*        <a href="https://www.facebook.com/westham/" className="text-center" target="_blank"*/}
      {/*           rel="noopener noreferrer">*/}
      {/*          <img*/}
      {/*            className="mx-auto"*/}
      {/*            src={require("../images/fb.png")}*/}
      {/*            alt="Facebook"*/}
      {/*            style={{width: "100px", height: "100x", objectFit: "cover", objectPosition: "center"}}*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*        <a href="https://twitter.com/westham" className="text-center" target="_blank"*/}
      {/*           rel="noopener noreferrer">*/}
      {/*          <img*/}
      {/*            className="mx-auto"*/}
      {/*            src={require("../images/twitter.png")}*/}
      {/*            alt="Twitter"*/}
      {/*            style={{width: "100px", height: "100x", objectFit: "cover", objectPosition: "center"}}*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*        <a href="https://www.instagram.com/westham/?hl=en" className="text-center" target="_blank"*/}
      {/*           rel="noopener noreferrer">*/}
      {/*          <img*/}
      {/*            className="mx-auto"*/}
      {/*            src={require("../images/insta.png")}*/}
      {/*            alt="Instagram"*/}
      {/*            style={{width: "100px", height: "100x", objectFit: "cover", objectPosition: "center"}}*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*        <a href="https://www.linkedin.com/company/west-ham-united/?originalSubdomain=uk" className="text-center" target="_blank"*/}
      {/*           rel="noopener noreferrer">*/}
      {/*          <img*/}
      {/*            className="mx-auto"*/}
      {/*            src={require("../images/linkedin.png")}*/}
      {/*            alt="LinkedIn"*/}
      {/*            style={{width: "100px", height: "100x", objectFit: "cover", objectPosition: "center"}}*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section>
        {!paymentDetails && SubscriptionPlan ? (
          <section className="subscription-plan-home font-face-sh draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100" style={{ zIndex: 999 }}>
            <div className="row align-items-center">
              <div className="col-sm-9 col-md-9 col-lg-9 col-9">
                <h1 className="text-light" style={{ color: "white" }}>
                  Enter the draw
                </h1>
                <p className="text-light">How many tickets would you like to buy?</p>
              </div>
              <div
                className="col-sm-3 col-md-3 col-lg-3 col-3 text-center border-top bg-white"
                style={{
                  backgroundColor: "#000",
                  borderBottomLeftRadius: 70,
                  borderBottomRightRadius: 70,
                  padding: "14px 0px",
                  marginTop: "-23px",
                }}
              >
                <h4 className="text-dark">Total</h4>
                <h4 className="text-light d-flex justify-content-center align-items-center">
                  <div className="subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1">£</div>
                  <div className="subscription-plan-price_amount branding-1 display-3 font-face-sh-bold">{amount}</div>
                </h4>
              </div>
            </div>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 700 }}>
              <div
                className={Freemodal ? "steps-subscription-plan px-0 position-relative pt-5 d-none" : "steps-subscription-plan px-0 position-relative d-block"}
                style={{ zIndex: 999 }}
              >
                <div className="ticket-entry">
                  {/* <div className="form-check form-switch">
                    <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">
                      {!isoneoff ? "Tick to make a one-off entry into the next draw" : "Untick to make a Subscription entry into the next draw"}
                    </label>
                  </div> */}

                  <div className="row mt-3">
                    <div className="col-sm-8 col-md-8 col-lg-8 col-8">
                      <div className="step-subscription-plan-selector home-data">
                        <ul className="d-flex unstyled list-unstyled gap-3">
                          <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 1)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{
                                backgroundColor: "#fff",
                                width: 42,
                                height: 42,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£5</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>1
                            </strong>
                          </li>
                          <li className={isActive2 ? "position-relative active" : "position-relative"} onClick={() => handleClick("second", 10, 2)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{
                                backgroundColor: "#fff",
                                width: 42,
                                height: 42,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£10</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>2
                            </strong>
                          </li>
                          <li className={isActive3 ? "active" : ""} onClick={() => handleClick("third", 25, 5)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{
                                backgroundColor: "#fff",
                                width: 42,
                                height: 42,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£25</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>5
                            </strong>
                          </li>
                          <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 50, 10)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{
                                backgroundColor: "#fff",
                                width: 42,
                                height: 42,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£50</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>
                              10
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 col-4">
                      <div className="step-subscription-button text-center d-flex justify-content-center">
                        {Moment() < Moment(DrawData?.drawStartDate) ? (
                          <>
                            <button
                              onClick={handleShow}
                              className="btn bg-branding-2 border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                              style={{ width: 400, height: 48 }}
                            >
                              <span>{"Buy Now"}</span>
                              {/* <span>{isoneoff ? "Buy Now" : "Subscribe"}</span> */}
                            </button>
                            <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-startShow">
                              <Modal.Header closeButton>
                                <Modal.Title>Draw has not started yet</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="text-center">
                                  <img className="mx-auto w-50 mb-3" src={require("../images/WHUFK_Logo_White.png")} style={{ width: 66 }} />
                                  <p className="fs-3">
                                    This draw will start at{" "}
                                    <span className="branding-2">
                                      {DrawData?.drawEndDate ? Moment(DrawData?.drawStartDate).format("MMMM Do, [at] ha") : "-----, /--/--/-- 00:00PM"}
                                    </span>
                                  </p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <span variant="secondary" onClick={handleClose}>
                                  Close
                                </span>
                              </Modal.Footer>
                            </Modal>
                          </>
                        ) : (
                          <button
                            onClick={() => toPaymentDetails(true)}
                            className="btn bg-branding-2 border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                            style={{ width: 400, height: 48 }}
                          >
                            <span>{"Buy Now"}</span>
                            {/* <span>{!isoneoff ? "Subscribe" : "Buy Now"}</span> */}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                <div className="free-entry-modal_title_block mb-5 pb-5">
                  <img
                    className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                    src={Cross}
                    style={{
                      width: 56,
                      boxShadow: "0 4px 16px rgb(20 19 38 / 30%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setFreemodal(!Freemodal)}
                  />
                  <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                  <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                    While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                  </p>
                </div>
                <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                  <div className="free-entry-icon">
                    <img src={FreeEntryIcon} style={{ width: 24 }} />
                  </div>
                  <div className="free-entry-description ps-4 fs-14 lh-1">
                    <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                    <p>
                      * First Name
                      <br />
                      * Last Name
                      <br />* Email
                    </p>
                    <p>
                      to: PFW Holdings Ltd
                      <br />
                      Play Fund Win Office #300,
                      <br />4 Blenheim Court,
                      <br />
                      Peppercorn Close,
                      <br />
                      Peterborough,
                      <br />
                      PE1 2DU
                      <br />
                      <br />
                      You will be entered into the prize draws for the month in which we receive your post entries.
                      <br />
                      <br />
                      Note: This will not result in any contributions to the Foundation
                    </p>
                    <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                      <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : paymentDetails && !oneShow ? (
          <section
            className="personal-details font-face-sh py-5 position-fixed"
            style={{
              backgroundColor: "#fff",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 999999,
              overflow: "scroll",
            }}
          >
            <a className="black-clr text-decoration-none" href="/">
              <img className="mx-3" src={require("../images/back.png")} style={{ width: 32 }} />
            </a>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                  <div
                    className="step-plan_step branding-1 rounded-pill text-center mx-auto"
                    style={{
                      backgroundColor: "#f7f7fd",
                      width: 112,
                      height: 45,
                      lineHeight: "45px",
                    }}
                  >
                    <strong>Step 2 of 2</strong>
                  </div>
                  {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                  <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                    {isoneoff ? "" : "We’ll take your amount "}
                  </p>
                  <form method="post">
                    <div className="form-checkbox">
                      <ul className="checkbox-list list-unstyled">
                        <li className="checkbox-item mb-4">
                          <label className="input-checkbox d-flex">
                            <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, I’d like to receive updates about West Ham United Foundation via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label className="input-checkbox d-flex">
                            <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, West Ham United Foundation can contact me about their work via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label
                            className="step-payment-details_consent_description mt-3 input-checkbox d-flex"
                            style={{
                              backgroundColor: "rgb(198,29,35,.1)",
                              color: "#302d51",
                              padding: "14px 18px",
                              borderRadius: 20,
                              fontSize: 13,
                            }}
                          >
                            <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span>
                              By clicking on Confirm you agree to West Ham United Foundation{" "}
                              <Link to={"/terms-conditions"} onClick={() => window.scrollTo(0, 0)} style={{ color: "#302d51" }}>
                                Terms & Conditions of Use
                              </Link>
                              ,{" "}
                              <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                Privacy Policy
                              </Link>{" "}
                              and that you <strong>are at least 18 years old.</strong>
                            </span>
                          </label>
                        </li>
                        <li className="checkbox-item pt-4 pb-0">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(event) => setFullName(event.target.value)}
                            className="input mb-3"
                          />
                          {/* <input type="text" id="firstname" value={userFirstName} onChange={(event) => setFirstName(event.target.value)} placeholder="Enter first name" className="input" style={{ display: 'block' }} />
                            <input type="text" id="lastname" value={userLastName} onChange={(event) => setLastName(event.target.value)} placeholder="Enter last name" className="input" style={{ display: 'block' }} /> */}
                          <input
                            type="email"
                            id="email"
                            value={userEmail}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Email address"
                            className="input"
                            style={{ display: "block" }}
                          />
                        </li>
                      </ul>
                    </div>
                    {/* <p className='step-payment-details_consent_description mt-5' style={{ backgroundColor: 'rgb(198,29,35,.1)', color: '#302d51', padding: '14px 18px', borderRadius: 20, fontSize: 13 }}>By clicking on Confirm you agree to West Ham United Foundation <Link to={"/terms-conditions"} style={{ color: '#302d51' }}>Terms & Conditions of Use</Link>, <Link to={"/privacy-policy"} style={{ color: '#302d51' }}>Privacy Policy</Link> and that you <strong>are at least 18 years old.</strong></p> */}
                    <div className="step-subscription-button text-center mb-3 d-flex justify-content-center"></div>
                  </form>
                  <button
                    onClick={checkConsents}
                    className="btn bg-branding-green border-0 rounded-pill py-2 mt-3 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: 48 }}
                  >
                    Pay now
                  </button>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                  <div
                    className="user-details-info bg-branding-1 rounded-4"
                    style={{
                      padding: "35px 35px 19px",
                      boxShadow: "0 15px 50px -30px #000000",
                    }}
                  >
                    <h3 className="text-light fs-5 mb-4">Summary</h3>
                    <div className="summary-info-impact pb-3">
                      <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                        {entries} chances to win
                      </div>
                      <div
                        className="summary-info-impact_period lh-1"
                        style={{
                          color: "rgb(255,255,255,0.7)",
                          paddingLeft: 35,
                        }}
                      >
                        every draw
                      </div>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <div className="summary-info-price d-flex align-items-center pb-2">
                      <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                      <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                        {isoneoff ? "" : ""}
                      </span>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                      Upgrade or cancel anytime
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {isStripe ? (
          <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Elements stripe={stripePromise}>
                <StripeForm
                  UserID={UserID}
                  oneoff={isoneoff}
                  show={true}
                  email={userEmail}
                  name={fullName}
                  address1="-"
                  address2="-"
                  postcode="-"
                  amount={amount}
                  entries={entries}
                  hideModal={hideModal}
                  navigate={navigate}
                  timeout={timeout}
                />
              </Elements>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        <ToastContainer />

        {/* </div> */}
      </section>

      <footer className="font-face-sh mt-5 position-relative home-footer">
        <div className="container">
          <div className="footer-logo">
            <img className="" src={LogoRed || ""} alt="logo-foundation-gray" style={{ width: 270 }} />
          </div>
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-5 black-clr">
              <h4 className="mb-4 pb-2 pt-2 font-face-sh-bold">Contact us</h4>
              <div className="footer-address d-flex align-items-start">
                <img className="me-3" src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className="footer-email d-flex align-items-start">
                <img className="me-3" src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>
                  Support:{" "}
                  <a href="mailto:hello@playfundwin.com" className="text-decoration-none black-clr branding-1-hover">
                    hello@playfundwin.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 mt-md-5">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 black-clr mb-3">
                  <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                  <div className="d-flex">
                    <a className="branding-2 text-decoration-none" href="https://www.facebook.com/WHUFoundation/?locale=en_GB" target="_blank">
                      <FacebookIcon fontSize="large" />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://www.linkedin.com/company/whufoundation/?originalSubdomain=uk" target="_blank">
                      <LinkedInIcon fontSize="large" />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://twitter.com/WHUFoundation?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                      <XIcon fontSize="large" />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://www.instagram.com/whufoundation/?hl=en" target="_blank">
                      <InstagramIcon fontSize="large" />
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex mt-md-5 pt-2">
                    <img className="me-3" src={Visa} alt="Visa" style={{ width: 54 }} />
                    <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 54 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4  mt-md-5 black-clr ps-md-6">
              <p className="mt-4 pt-3" style={{ fontSize: 14 }}>
                West Ham Prize Draw is operated by{" "}
                <a className="text-decoration-none black-clr branding-1-hover" href="https://playfundwin.com/" target="_blank">
                  Play Fund Win
                </a>
              </p>
              <div className="d-flex" style={{ fontSize: 14 }}>
                <Link className="black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold" onClick={() => window.scrollTo(0, 0)} to={"/terms-conditions"}>
                  Terms & Conditions
                </Link>
                <Link className="black-clr text-decoration-none branding-1-hover font-face-sh-bold" onClick={() => window.scrollTo(0, 0)} to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
