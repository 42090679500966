import React from "react";
import LogoFoundationGray from "../images/mancity-horizontal-dark.png";
import LogoFoundation from "../images/WHUF_Horizontal_Digital.png";

import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import "../App.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footer-section font-face-sh mt-5 position-relative mobile-footer">
        <div className="container">
          <div className="footer-logo">
            <img className="" src={LogoFoundation} alt="logo-foundation-gray" style={{ width: 270 }} />
          </div>
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-5 black-clr">
              <h4 className="mb-4 pb-2 pt-2 font-face-sh-bold">Contact us</h4>
              <div className="footer-address d-flex align-items-start">
                <img className="me-3" src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className="footer-email d-flex align-items-start">
                <img className="me-3" src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>
                  Support:{" "}
                  <a href="mailto:hello@playfundwin.com" className="text-decoration-none black-clr branding-1-hover">
                    hello@playfundwin.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 mt-md-5">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 black-clr mb-3">
                  <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                  <div className="d-flex">
                    <a className="black-clr text-decoration-none" href="https://facebook.com/PlayFundWin/" target="_blank">
                      <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                    </a>
                    <a className="black-clr text-decoration-none" href="https://twitter.com/PlayFundWin" target="_blank">
                      <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                    </a>
                    <a className="black-clr text-decoration-none" href="https://www.instagram.com/playfundwin" target="_blank">
                      <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex mt-md-5 pt-2">
                    <img className="me-3" src={Visa} alt="Visa" style={{ width: 64 }} />
                    <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 64 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4  mt-md-5 black-clr ps-md-5">
              <p className="mt-4 pt-3" style={{ fontSize: 13 }}>
                West Ham United Foundation Prize Draw is operated by{" "}
                <a className="text-decoration-none black-clr branding-1-hover" href="https://playfundwin.com/" target="_blank">
                  Play Fund Win
                </a>
              </p>
              <div className="d-flex" style={{ fontSize: 14 }}>
                <Link className="black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold" onClick={() => window.scrollTo(0, 0)} to={"/terms-conditions"}>
                  Terms & Conditions
                </Link>
                <Link className="black-clr text-decoration-none branding-1-hover font-face-sh-bold" onClick={() => window.scrollTo(0, 0)} to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
